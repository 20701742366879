<template>
  <div class="p-grid">
    <Card class="style p-col-2">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div class="p-inputgroup">
            <div>
              <h3 class="p-mt-0"><strong>{{ _t('label_orgs') }}</strong></h3>
            </div>
          </div>
          <Button
              v-if="!isEditable"
              v-tooltip.bottom="_t('Create')"
              class="p-button-rounded p-button-success p-button-text"
              icon="pi pi-plus"
              style="float: right;"
              @click="newOrganizationDialog = true; newOrganization.parentId = selectedOrg.id; newOrganization.parentName = selectedOrg.name;"
          />
        </div>
      </template>
      <template #content>
        <TreeTable
            v-model:expandedKeys="expandedOrganizations"
            v-model:selectionKeys="selectedNode"
            :autoLayout="true"
            :scrollable="true"
            :value="organizations"
            class="p-treetable-sm"
            scrollHeight="85vh"
            selectionMode="single"
            stripedRows
            style="margin: -1.5rem; font-size: 12px; padding: 0px 5px 0px 0px;!important; height:85vh;"
            @node-expand="appendNode($event)"
            @node-select="setOrgId($event, 'select')"
        >
          <Column :expander="true" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
            <template #body="slotProps">
              <i :class="getOrgIcon(slotProps.node.data.category)+' p-mr-2 color-green'" style="font-size:1.5em;"/>
              {{ slotProps.node.data.name }}
            </template>
          </Column>
        </TreeTable>
      </template>
    </Card>
    <transition name="slide-fade">
      <Card v-show="selectedOrg && selectedOrg.id !== null" class="p-col">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div>
              <h3 class="p-m-0"><strong>{{ selectedOrg.name }}</strong></h3>
            </div>
            <div>
              <Button v-if="isEditable" class="p-button-rounded p-button-danger p-button-text" icon="pi pi-times"
                      v-tooltip.bottom="_t('Cancel')"
                      @click="isEditable = false; getOrganization()"/>
              <Button
                  :class="isEditable ? 'p-button-rounded p-button-text p-button-success' : 'p-button-rounded p-button-text p-button-secondary' "
                  v-tooltip.bottom="isEditable ? _t('Save') : _t('Edit')"
                  :disabled="selectedOrg.id === null || selectedOrg.id === '1'"
                  :icon="isEditable ? 'pi pi-check' : 'pi pi-pencil'"
                  @click=" isEditable ? saveOrganization(): editOrganization()"
              />
              <Button
                  v-if="!isEditable"
                  v-tooltip.bottom="_t('label_clone_org')"
                  :disabled="!selectedOrg.id || selectedOrg.id === null || selectedOrg.id == 1"
                  class="p-button-rounded p-button-success p-button-text"
                  icon="pi pi-clone"
                  @click="initCloneOrganizationDialog()"
              />
              <Button
                  v-if="!isEditable"
                  v-tooltip.bottom="_t('Delete')"
                  :disabled="!selectedOrg.id || selectedOrg.id === null || selectedOrg.id == 1"
                  class="p-button-rounded p-button-danger p-button-text"
                  icon="pi pi-trash"
                  @click="deleteOrganizationDialog = true"
              />
            </div>
          </div>
        </template>
        <template #content>
          <TabView v-model:activeIndex="activeTab">
            <TabPanel :header="_t('menu.general')">
              <div class="p-grid">
                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_object_name') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedOrg.name }}</strong></p>
                  <InputText v-else v-model="selectedOrg.name" type="text"/>
                  <br v-if="isEditable">
                  <small v-if="orgCreateError.includes('name')"
                         class="p-error">{{ _t('label_org_name_required') }}</small>
                  <small v-if="orgCreateError.includes('nameMax')"
                         class="p-error">{{ _t('label_org_name_max') }}</small>
                </div>
                <div :class="`style p-col-2 `" style="vertical-align: middle;">
                  <p>{{ _t('label_description') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>{{ selectedOrg.description }}</strong></p>
                  <InputText v-else v-model="selectedOrg.description" type="text"/>
                  <br v-if="isEditable">
                  <small v-if="orgCreateError.includes('descriptionMax')" class="p-error">
                    {{ _t('label_org_description_max') }}
                  </small>
                </div>
                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_cathegory') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <p v-if="!isEditable"><strong>
                    <SelectedOption :options="orgCategories" v-model="selectedOrg.category" option-label="label"
                                    option-value="value"/>
                  </strong></p>
                  <Dropdown
                      v-else
                      v-model="selectedOrg.category"
                      :options="orgCategories"
                      option-label="label"
                      option-value="value"
                  />
                  <small v-if="orgCreateError.includes('category')"
                         class="p-error">{{ _t('label_category_required') }}</small>
                </div>
              </div>
            </TabPanel>
            <TabPanel
                :disabled="selectedOrg && (selectedOrg.category !== 'GEOGRAPHICAL_SCOPE' || selectedOrg.limitable === false)"
                :header="_t('label_Limits')">
              <div class="p-grid">
                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_alarm_plans') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <InputNumber v-model="limits.alarmplan_LIMIT" :disabled="!isEditable"/>
                    <Button :disabled="!isEditable" label="Limitless" style="background-color:#82B541; border:none;"
                            @click="limits.alarmplan_LIMIT = 0"/>
                  </div>
                </div>
                <div :class="`style p-col-2 `" style="vertical-align: middle;">
                  <p>{{ _t('label_alarm_points') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <InputNumber v-model="limits.alarmpoint_LIMIT" :disabled="!isEditable"/>
                    <Button :disabled="!isEditable" label="Limitless" style="background-color:#82B541; border:none;"
                            @click="limits.alarmpoint_LIMIT = 0"/>
                  </div>
                </div>
                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_ap_rotationals') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <InputNumber v-model="limits.alarmpointrotational_LIMIT" :disabled="!isEditable"
                    />
                    <Button :disabled="!isEditable" label="Limitless" style="background-color:#82B541; border:none;"
                            @click="limits.alarmpointrotational_LIMIT = 0"/>
                  </div>
                </div>
                <div :class="`style p-col-2 `" style="vertical-align: middle;">
                  <p>{{ _t('label_participants') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <InputNumber v-model="limits.participant_LIMIT" :disabled="!isEditable"/>
                    <Button :disabled="!isEditable" label="Limitless" style="background-color:#82B541; border:none;"
                            @click="limits.participant_LIMIT = 0"/>
                  </div>
                </div>
                <div :class="`style p-col-2 stripedBg`" style="vertical-align: middle;">
                  <p>{{ _t('label_pgs') }}</p>
                </div>
                <div :class="`style p-col-10 stripedBg`" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <InputNumber v-model="limits.participantgroup_LIMIT" :disabled="!isEditable"/>
                    <Button :disabled="!isEditable" label="Limitless" style="background-color:#82B541; border:none;"
                            @click="limits.participantgroup_LIMIT = 0"/>
                  </div>
                </div>
                <div :class="`style p-col-2 `" style="vertical-align: middle;">
                  <p>{{ _t('label_pt_rotationals') }}</p>
                </div>
                <div :class="`style p-col-10 `" style="vertical-align: middle;">
                  <div class="p-inputgroup">
                    <InputNumber v-model="limits.participantrotational_LIMIT" :disabled="!isEditable"
                    />
                    <Button :disabled="!isEditable" label="Limitless" style="background-color:#82B541; border:none;"
                            @click="limits.participantrotational_LIMIT = 0"/>
                  </div>
                </div>
              </div>
            </TabPanel>
            <!--            <TabPanel disabled :header="_t('label_map')">-->
            <!--              <Map>-->
            <!--              </Map>-->
            <!--            </TabPanel>-->
          </TabView>
        </template>
      </Card>
    </transition>
  </div>

  <Dialog v-model:visible="newOrganizationDialog" :style="{width: '450px'}">
    <template #header></template>
    <h3>{{ _t('label_create_new_org') }}</h3>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-pencil"></i>
      </span>
          <InputText
              v-model="newOrganization.name"
              :placeholder="_t('label_org_name')"
              style="margin: 0px;"/>
        </div>
        <small v-if="orgCreateError.includes('name')" class="p-error">{{ _t('label_org_name_required') }}</small>
        <small v-if="orgCreateError.includes('nameMax')" class="p-error">{{ _t('label_org_name_max') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
          <InputText
              v-model="newOrganization.description"
              :placeholder="_t('label_description')"/>
        </div>
        <small v-if="orgCreateError.includes('descriptionMax')"
               class="p-error">{{ _t('label_org_description_max') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
          <Dropdown
              v-model="newOrganization.category"
              :options="orgCategories"
              :placeholder="_t('label_cathegory')"
              option-label="label"
              option-value="value"
          />
        </div>
        <small v-if="orgCreateError.includes('category')" class="p-error">{{ _t('label_category_required') }}</small>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <p>{{ _t('label_parent_organization') }} <strong
            @click="selectParentOrgDialog = true">{{ newOrganization.parentName }}</strong>
        </p>
      </div>
    </div>
    <template #footer>
      <Button class="p-button-outlined p-button-secondary" :label="_t('Cancel')"
              @click="newOrganizationDialog = false"/>
      <Button autofocus class="p-button-success" :label="_t('Save')" @click="createOrganization()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="cloneOrganizationDialog">
    <template #header></template>
    <h3>{{ _t('label_clone_org') }} <strong class="color-green">{{ selectedOrg.name }}</strong></h3>
    <div class="p-inputgroup p-mb-2">
      <span class="p-inputgroup-addon">
        <i class="pi pi-pencil"></i>
      </span>
      <InputText
          v-model="newOrganization.name"
          :placeholder="_t('label_org_name')"
          style="margin: 0px;"/>
      <small v-if="orgCreateError.includes('name')" class="p-error">{{ _t('label_org_name_required') }}.</small>
      <small v-if="orgCreateError.includes('nameMax')" class="p-error">{{ _t('label_org_name_max') }}</small>
    </div>
    <div class="p-inputgroup p-mb-2">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText
          v-model="newOrganization.description"
          :placeholder="_t('label_description')"/>
      <small v-if="orgCreateError.includes('descriptionMax')"
             class="p-error">{{ _t('label_org_description_max') }}</small>
    </div>
    <div class="p-inputgroup  p-mb-4">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <Dropdown
          v-model="newOrganization.category"
          :options="orgCategories"
          :placeholder="_t('label_cathegory')"
          option-label="label"
          option-value="value"
      />
      <small v-if="orgCreateError.includes('category')" class="p-error">{{ _t('label_category_required') }}</small>
    </div>
    <p>
      {{ _t('label_parent_organization') }}
      <strong @click="selectParentOrgDialog = true">
        {{ newOrganization.parentName }}
      </strong>
    </p>
    <template #footer>
      <Button class="p-button-outlined p-button-secondary" :label="_t('Cancel')"
              @click="cloneOrganizationDialog = false"/>
      <Button autofocus class="p-button-success" :label="_t('Save')" @click="createOrganization()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteOrganizationDialog">
    <template #header>
    </template>
    <h3>{{ _t('label_delete_operation') }}</h3>
    <p><strong class="color-green">{{ selectedOrg.name }}</strong> {{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button class="p-button-text" icon="pi pi-times" :label="_t('Cancel')" @click="deleteOrganizationDialog = false"/>
      <Button autofocus icon="pi pi-check" :label="_t('Delete')" @click="deleteOrganization()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="selectParentOrgDialog" :modal="true" :style="{width: '450px'}" header=" "
          @close="selectParentOrgDialog = false">
    <TreeTable
        v-model:expandedKeys="expandedOrganizations"
        v-model:selectionKeys="selectedNode"
        :autoLayout="true"
        :scrollable="true"
        :value="organizations"
        class="p-treetable-sm"
        scrollHeight="300"
        selectionMode="single"
        stripedRows
        style="margin: -1.5rem; font-size: 12px; padding: 0!important;"
        @node-expand="appendNode($event)"
        @node-select="setOrgId($event, newOrganizationDialog === true ? 'new' : 'edit')"
    >
      <Column :expander="true" field="name" style="overflow-x:0!important;"/>
    </TreeTable>
  </Dialog>
</template>

<script>
import {getTree} from "@/api/tree";
import {createOrg, deleteOrg, getOrg, getOrgLimits, setOrgLimits, updateOrg} from '@/api/organization';
// import Map from "../../components/Map.vue";

export default {
  name: 'index',
  components: {
    // Map,
  },
  data() {
    return {
      isEditable: false,
      changePassDlg: false,
      selectParentOrgDialog: false,
      totalRecords: 100,
      currentPage: 0,
      organizations: [],
      expandedOrganizations: [],
      orgCreateError: [],
      selectedNode: {},
      loading: true,
      dataTabs: false,
      selectedOrg: {
        id: null,
        name: '',
        description: '',
        category: null,
        parentId: null,
      },
      orgId: null,
      limits: {
        alarmplan_LIMIT: 0,
        alarmpoint_LIMIT: 0,
        alarmpointrotational_LIMIT: 0,
        participant_LIMIT: 0,
        participantgroup_LIMIT: 0,
        participantrotational_LIMIT: 0
      },
      orgCategories: [],
      newOrganization: {
        name: '',
        description: '',
        category: null,
        parentId: null,
        parentName: '',
      },
      newOrganizationDialog: false,
      deleteOrganizationDialog: false,
      cloneOrganizationDialog: false,
      activeTab: 0,
    }
  },
  created() {
  },
  watch: {
    orgId(value) {
      console.log(value)
    }
  },
  mounted() {
    this.orgCategories =  [
      {value: 'GEOGRAPHICAL_SCOPE', label: this._t('GEOGRAPHICAL_SCOPE'), icon: 'mdi mdi-earth'},
      {value: 'BUILDING', label: this._t('BUILDING'), icon: 'mdi mdi-home'},
      {value: 'COMPANY_ORGANISATION', label: this._t('COMPANY_ORGANISATION'), icon: 'mdi mdi-sitemap'},
      {value: 'ALARM_GROUP_ORGANISATION', label: this._t('ALARM_GROUP_ORGANISATION'), icon: 'mdi mdi-deskphone'},
      {value: 'ALARM_COLLECTING_POINTS_GROUP', label: this._t('ALARM_COLLECTING_POINTS_GROUP'), icon: 'mdi mdi-phone-in-talk'},
      {value: 'PART_OF_BUILDING', label: this._t('PART_OF_BUILDING'), icon: 'mdi mdi-office-building-outline'},
      {value: 'ROOM', label: this._t('ROOM'), icon: 'mdi mdi-floor-plan'},
      {value: 'GROUP_OF_INSTALLATIONS', label: this._t('GROUP_OF_INSTALLATIONS'), icon: 'mdi mdi-information'},
      {value: 'HARDWARE_GROUP', label: this._t('HARDWARE_GROUP'), icon: 'mdi mdi-router-wireless'}
    ]
    this.orgId = this.$route.params.id
    this.selectedOrg = {
      id: null,
          name: '',
          description: '',
          category: null,
          parentId: null,
    }
    if (this.orgId > 1) {
      this.getOrganization()
    }
    this.getOrganizations()
  },
  methods: {
    getOrganization() {
      if (this.orgId) {
        getOrg(this.orgId).then((response) => {
          this.selectedOrg = response.data
          if (this.orgId != 1) {
            this.getOrganizationLimits()
          }
          this.dataTabs = true
          this.orgCreateError = []
        })
      }
    },
    getOrganizationLimits() {
      if (this.orgId) {
        getOrgLimits(this.orgId).then((response) => {
          this.limits = {
            alarmplan_LIMIT: response.data.alarmplan_LIMIT ?? 0,
            alarmpoint_LIMIT: response.data.alarmpoint_LIMIT ?? 0,
            alarmpointrotational_LIMIT: response.data.alarmpointrotational_LIMIT ?? 0,
            participant_LIMIT: response.data.participant_LIMIT ?? 0,
            participantgroup_LIMIT: response.data.participantgroup_LIMIT ?? 0,
            participantrotational_LIMIT: response.data.participantrotational_LIMIT ?? 0
          }
        })
      }
    },
    setOrganizationLimits() {
      setOrgLimits(this.orgId, this.limits).then((response) => {
        this.limits = response.data
      }).catch((error) => {
        console.log(error)
        let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
    },
    editOrganization() {
      this.isEditable = !this.isEditable
    },
    saveOrganization() {
      if (!this.validateOrg(this.selectedOrg)) {
        return false;
      }
      updateOrg(this.selectedOrg).then((response) => {
        this.selectedOrg = response.data
        this.setOrganizationLimits()
        this.getOrganizations()
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })

      this.isEditable = !this.isEditable
    },
    getOrganizations() {
      getTree().then((response) => {
        this.organizations = response.data
            .map(({name, category, hasChildren, id}) => ({
              data: {name, category},
              leaf: !hasChildren,
              key: id,
              parentId: null
            }))
        if (this.organizations.length > 0) {
          this.appendNode(this.organizations[0])
          this.expandedOrganizations[this.organizations[0].key] = true;
          this.selectedNode[this.organizations[0].key] = true;
          if(!this.orgId) {
            this.setOrgId(this.organizations[0])
          }
        }
      })
    },
    setOrgId(org, type = 'select') {
      this.activeTab = 0;
      if (type === 'edit') {
        this.selectedOrg.parentId = org.key;
        this.selectedOrg.parentName = org.data.name;
        this.newOrganization.parentId = org.key;
        this.newOrganization.parentName = org.data.name;
        this.selectParentOrgDialog = false
      }
      if (type === 'new') {
        this.newOrganization.parentId = org.key;
        this.newOrganization.parentName = org.data.name;
        this.selectParentOrgDialog = false
      }

      if (type === 'select') {
        this.orgId = org.key ?? this.selectedOrg.id
        this.isEditable = false
        this.getOrganization()
      }
    },
    appendNode(item) {
      getTree(item.key).then((response) => {
        item.children = response.data
            .map(({name, category, hasChildren, id}) => ({
              data: {name, category},
              leaf: !hasChildren,
              key: id,
              parentId: item.key
            }))
      })
    },
    deleteOrganization() {
      this.deleteOrganizationDialog = false;
      deleteOrg(this.selectedOrg.id).then(() => {
        if (this.selectedOrg && this.selectedOrg.parentId) {
          this.setOrgId(this.selectedOrg.parentId)
          this.newOrganization.parentId = this.selectedOrg.parentId;
          this.deleteNode(this.organizations, this.selectedOrg.id);
          this.selectedNode[this.orgId] = true;
          this.getOrganization();
        }
        this.resetSelectedOrg();
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ?? 'Error deleting organization'), 'error')
      })
    },
    deleteNode(treeNodes, searchNodeId) {
      return treeNodes.map((node, index) => {
        if (parseInt(node.key) === parseInt(searchNodeId)) {
          treeNodes.splice(index, 1);

        } else {
          if (node.children) {
            return this.deleteNode(node.children, searchNodeId);
          }
        }
      });
    },
    addNode(treeNodes, searchNodeId, orgData) {
      return treeNodes.map(node => {
        if (parseInt(node.key) === parseInt(searchNodeId)) {
          const item = {
            data: {name: orgData.name},
            leaf: !orgData.hasChildren,
            key: orgData.id,
            parentId: orgData.parentId
          };
          if (node.children) {
            node.children.splice(0, 0, item)
            node.children.sort((a, b) => ('' + a.data.name).localeCompare(b.data.name));
          } else {
            node.children = [];
            node.children.splice(0, 0, item)
          }
        } else {
          if (node.children) {
            return this.addNode(node.children, searchNodeId, orgData);
          }
        }
      });
    },
    validateOrg(organization) {
      let valid = true
      console.log('a')
      this.orgCreateError = []
      if (!organization.name || !organization.name.length) {
        console.log('b')
        this.orgCreateError.push('name')
        valid = false
      }
      if (organization.name && organization.name.length > 255) {
        console.log('c')
        this.orgCreateError.push('nameMax')
        valid = false
      }
      if (organization.description && organization.description.length > 255) {
        console.log('d')
        this.orgCreateError.push('descriptionMax')
        valid = false
      }
      if (!organization.category) {
        console.log('e')
        this.orgCreateError.push('category')
        valid = false
      }

      return valid
    },
    createOrganization() {
      if (!this.validateOrg(this.newOrganization)) {
        return false
      }
      createOrg(this.newOrganization).then(({data}) => {
        if (data.parentId) {
          this.setOrgId(data.parentId)
          this.addNode(this.organizations, data.parentId, data);
        }
        this.newOrganizationDialog = false
        this.cloneOrganizationDialog = false
        this.resetNewOrganization()
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
    },
    resetSelectedOrg() {
      this.orgId = null;
      this.selectedOrg.id = null;
      this.selectedOrg.name = '';
      this.selectedOrg.description = '';
      this.selectedOrg.category = null;
      this.selectedOrg.parentId = null;
    },
    resetNewOrganization() {
      this.orgId = null;
      this.newOrganization.name = '';
      this.newOrganization.description = '';
      this.newOrganization.category = null;
      this.newOrganization.parentId = null;
      this.newOrganization.parentName = '';
    },
    initCloneOrganizationDialog() {
      this.newOrganization = {
        name: this.selectedOrg.name,
        description: this.selectedOrg.description,
        category: this.selectedOrg.category,
        parentId: this.selectedOrg.id,
        parentName: this.selectedOrg.name
      }
      this.cloneOrganizationDialog = true
    },
    getOrgIcon(category) {
      let orgCategory = this.orgCategories.find(item => item.value == category)
      if (orgCategory) {
        return orgCategory.icon
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-treetable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-treetable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-treetable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-treetable) {
  .p-treetable-header {
    padding: 5px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    text-align: left;
    padding: 0px;
  }

  .p-treetable-tbody > tr > td {
    cursor: auto;
    padding: 0px;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-treetable-customers .p-treetable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-treetable) {
    &.p-treetable-customers {
      .p-treetable-thead > tr > th,
      .p-treetable-tfoot > tr > td {
        display: none !important;

      }

      .p-treetable-tbody > tr {
        border-bottom: 1px solid var(--surface-d);

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-treetable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 0px;
  }

  .p-treetable-thead > tr > th {
    padding: 0px;
  }

}

.customer, .limit {
  font-family: Roboto, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 12px !important;
  border-collapse: collapse;
  width: 100%;
}

.customer td, .customers th {
  border: 1px solid #ddd;
  padding: 1em;
}

.limit td, .customers th {
  border: 1px solid #ddd;
  padding: 0.5em 1em;
}

.customer tr:nth-child(even) {
  background-color: #f2f2f2;
}

.limit tr:nth-child(even) {
  background-color: #f2f2f2;
}

.stripedBg {
  background-color: #f9f9f9;
}

</style>
